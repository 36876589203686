.container-map {
  background-color: #1C2120;
  height: 80vh;
  overflow: hidden; /* Ensure child elements don't overflow */
  box-sizing: border-box; /* Include padding and borders in total width and height */
}

.map-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 55vw;
  height: 70%; /* Ensure it takes the full height of the parent container */
  overflow: hidden; /* Ensure img doesn't overflow */
  box-sizing: border-box;
}

.map-img img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  object-fit: contain; /* Ensure the image scales correctly */
}

@media screen and (max-width: 768px) {
  .container-map {
    height: 37vh;
    margin-top: 110px; /* Adjust as needed */
    overflow: hidden;
    padding: 10px 0; /* Adjust padding as needed */
  }
  
  .map-img {
    max-width: 80vw;
    height: 100%; /* Ensure it takes the full height of the parent container */
    overflow: hidden;
  }
}
