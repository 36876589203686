.services {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 90px;
  gap: 20px;
}

.service-cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
}

.service-image-container {
  width: 100%;
  height: 500px; /* Adjust height as needed */
  overflow: hidden;
  border-radius: 10px;
}

.service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-title {
  font-size: 18px;
  margin: 10px 0;
  /* text-align: center; */
  min-height: 48px; /* Ensure enough space for two lines of text */
  flex: 1;
}

.service-btn {
  background-color: #F6B548;
  padding: 8px 19px;
  margin-top: 10px;
  color: #fff;
  text-align: center;
  border: none;
  cursor: pointer;
  border-radius: 17px;
}

@media screen and (max-width: 768px) {
  .services {
    grid-template-columns: 1fr;
    margin: 0 10px;
    gap: 25px;
  }

  .service-cards {
    gap: 10px;
    padding: 20px;
  }

  .service-image-container {
    width: 100%;
    height: 400px; /* Adjust height as needed */
    overflow: hidden;
    border-radius: 10px;
  }
}
