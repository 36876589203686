.container-plan {
  margin: 0 auto; /* Центрируем контейнер по горизонтали */
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 45px;
}

.plan-desc{
  width: 85%;
  margin: 20px  auto;

}

.plan-desc #heading {
padding-bottom: 0;
text-align: left;
}

.plan-description{
  
  font-size: 1rem !important;
}

.img-product{
  border-radius: 15px;
 padding: 0 !important;
}

.plan1 {
  max-width: 650px; /* Устанавливаем максимальную ширину */
}

.plan2 {
  max-width: 650px; /* Устанавливаем максимальную ширину */
}

.list-plan {
  text-align: left;
  padding: 0 0 0;
  /* padding-right: 60px; */
}

.bg-plan {
  background-color: #1c2120;
  padding: 20px;
}

#title-plan {
  text-align: left !important;
  padding: 0;
  color: #4a4a4a !important;
}

.product1 #heading {
  text-align: left;
  color: #4a4a4a !important;
}

.single-product {
  margin: 50px;
}

.glowing-button {
  background-color: #f6b548;
  color: #fff;
  border: none;
  padding: 15px 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px #ffffff, 0 0 30px #fdc056, 0 0 60px #f6b548;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  margin-bottom: 90px;
}

.glowing-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px #f6b548, 0 0 40px #f6b548, 0 0 80px #f6b548;
}

.contact-form input {
  border: 1px solid black;
  margin-top: 30px;
}

.plan-price {
  width: fit-content;
  justify-content: center;
  align-items: center;
  align-self: center;
  /* background-color: #f6b548; */
  /* border-radius: 5px;
  padding: 10px; */
  padding-bottom: 10px;
  margin-bottom: 2px;
  color: white;
  text-align: center;
  font-weight: bold;
}

.plan-price p {
  font-size: 18px;
  margin: 0;
}

.plan-price p:not(:last-child) {
  margin-bottom: 1px;
}


.product-description {
  color: #4a4a4a !important;
}

.contact-form button {
  background-color: #f6b548;
}

@media screen and (max-width: 768px) {
  .product-cont {
    display: block;
  }

  .container-plan {
    margin-top: 10px;
  }
  .product-cont .row {
    width: 100% !important;
  }

  .single-product {
    margin: 20px;
  }

  .plan1 #heading h3 {
    text-align: left !important;
  }

  .plan2 #heading h3 {
    text-align: left !important;
  }

  .list-plan li {
    font-size: 0.8rem;
  }

  .container-plan {
    display: flex;
    justify-content: space-between;
  }

  .plan-description {
    flex: 1;
    margin-bottom: 1px !important;
  }

  .img-product {
    max-height: 320px !important;
    border-radius: 15px;
    padding: 0 !important;
  }
  .plan1{
    width: 350px !important;
  }

  .plan2{
    width: 350px !important;
  }
}

.comparison-table {
  width: 80%; /* Adjust the width as needed */
  margin: 0 auto; /* Center the table horizontally */
  border-collapse: collapse;
  font-size: 14px; /* Decrease font size */
  text-align: left;
  background-color: #1c2120;
  color: white;
}

.comparison-table th,
.comparison-table td {
  padding: 8px 12px; /* Decrease padding */
  border: 1px solid #ddd;
}

.comparison-table th {
  background-color: #1c2120;
  color: white;
}

.comparison-table tr:nth-child(even) {
  background-color: #2c3231;
}

.comparison-table tr:nth-child(odd) {
  background-color: #1c2120;
}

.comparison-table td {
  color: white;
  /* width: 50px; */
}

.table {
  background-color: #1c2120;
}

.container-plan {
  display: flex;
  justify-content: space-around;
}

.plan1,
.plan2 {
  width: 45%;
}

.list-plan {
  list-style-type: none;
  padding: 0;
}

.plan-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.plan-description {
  flex: 1;
  margin-bottom: 10px;
}

.plan-check {
  width: 20px;
  text-align: center;
}

@media screen and (max-width: 400px) {
  .plan-check {
    width: 0px;
    text-align: center;
  }

 
}


/* Your existing CSS here */

.single-plan {
  justify-content: center;
}

.plan1,
.plan2 {
  max-width: 650px; /* Ensure the plans don't get too wide */
  width: 45%;
}

.single-plan .plan1,
.single-plan .plan2 {
  width: 100%;
  max-width: 650px;
}


.plan2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}





.plan-price {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 20px;
}

.price-title {
  font-weight: bold;
  margin-right: 10px;
}

.plan-price span {
  font-size: 1em;
}

