.testimonial-container-test {
    max-width: 1100px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 25px;
    margin-top: 45px;
    /* height: 100vh; */
  }
  
  .title-test {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .title-test h2 {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1.7rem;
    line-height: 1.1;
  }
  
  /* Slider container */
  .slider-container-test {
    position: relative;
    user-select: none;
    padding-inline: 1rem;
  }
  
  .slider-container-test .quote {
    position: absolute;
    z-index: -1;
  }
  
  .slider-container-test .top-quote {
    position: absolute;
    top: -1rem;
    left: -1rem;
  }
  
  .slider-container-test .bottom-quote {
    position: absolute;
    bottom: -3rem;
    right: -0.5rem;
    transform: rotate(180deg);
  }
  

  
  
  /* Splide customization */
  .splide__track{
    border-radius: 1rem;
  }
  
  .splide__slide {
    /* display: flex; */
    align-items: center;
    background-color: #fff;
    padding: 3rem 1.875rem;
    border-radius: 1rem;
    gap: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .slider-container-test .content-test .text-test {
    color: #484646;
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }
  
  .slider-container-test .content-test .rating-test {
    display: inline-block;
    line-height: 1;
  }
  
  .slider-container-test .content-test .star-test {
    color: #ff6f59;
    font-size: 1.1rem;
  }
  
  .slider-container-test .content-test .user-test {
    font-weight: 600;
  }
  
  .splide__pagination__page.is-active {
    background-color: #ff6f59;
  }
  
  .splide__arrow {
    position: absolute;
    background-color: transparent;
    border: none;
    font-size: 2rem;
    color: #505050;
    font-weight: 300;
  }
  
  .splide__arrow--prev {
    top: 50%;
    left: -3.5rem;
  }
  
  .splide__arrow--next {
    top: 50%;
    right: -3.5rem;
  }
  
  .splide__pagination__page {
    height: 10px;
    width: 10px;
    opacity: 1;
  }

  .slider-container-test {
    position: relative;
  }
  
  .image-container-test {
    display: flex;
    justify-content: center; /* Центрирование по горизонтали */
    align-items: center; /* Центрирование по вертикали */
    height: 100%; /* Устанавливаем высоту контейнера */
  }
  
  .image-test {
    width: 40rem;
    border-radius: 1.5rem;
    object-fit: cover;
    margin-bottom: 1rem;
    
  }

  .highlight {
    color: orange !important;
  }
  
  
  /* Media Query */
  @media (max-width: 576px) {
    .splide__slide {
      /* display: block; */
      text-align: center;
    }
  
    .splide__arrow {
      font-size: 1.2rem;
    }
  
    .splide__arrow--prev {
      left: 0.5rem;
    }
  
    .splide__arrow--next {
      right: 0.5rem;
    }
  
    .slider-container-test .bottom-quote {
      right: 0;
    }

    .image-test {
        width: 18rem;
        border-radius: 1.5rem;
        object-fit: cover;
        /* margin-bottom: 1rem; */
        
      }

      .testimonial-container-test {
        max-width: 450px;
        margin-inline: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* height: 100vh; */
      }


  .splide__slide {
    /* display: flex; */
    align-items: center;
    background-color: #fff;
    padding: 3rem 0;
    border-radius: 1rem;
    gap: 1rem;
    margin-left: 0.5rem;
    margin-right: 1rem;
  }
  }



