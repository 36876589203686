.slider-container {
  background-color: #1C2120;
  height: 100vh;
}

.images-slider {
  padding: 50px 10px;
  background-color: none;
  width: 300px;
}

.slider-single-content {
  box-shadow: none !important;
}

.slider-heading {
  background-color: #1C2120;
}


@media screen and (max-width: 768px) {
  .slider-container {
    height: auto;
 padding-top: 50px;
 padding-bottom: 70px;

  }
}