.hero {
  background-image: url("../../../assets/Frame\ 26.png");
  background-size: cover;
  /* background-attachment: fixed; */
  background-position:center center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;

}


.hero #heading h3 {
  color: #fff;
  width: 100% !important;

}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
  width: 80% !important;

}

.facepalm{
  width: 60%;

}

.hero .container .row{
  width: 80% !important;
}

.button{
  margin-top: -20px;
}
@media screen and (max-width: 768px) {
  .margin {
    height: 59vh;
  }
  .hero {
    background-position: center;
    background-image: url("../../../assets/Blue\ Red\ White\ Playful\ Illustrated\ Kids\ Sport\ Winter\ Camp\ Flyer\ \(1\)\ 2.webp");
    padding-top: 50%;
    height: 100vh;
    background-attachment: scroll;
    background-repeat:no-repeat ;
    object-fit: fill;
  text-shadow: 1px 1px 13px rgba(255,255,255,0.86);

  }
  .hero .row {
    width: 100%;
  }
  .hero #heading {
    text-align: left;
    margin: 0;
    padding: 0;
    width: 100% !important;
  text-shadow: 1px 1px 13px rgba(255,255,255,0.86);

  }
  .hero .container .row{
    width: 100% !important;
  }

  .facepalm{
    width: 250px;
  text-shadow: 1px 1px 13px rgba(255,255,255,0.86);

  }
}
