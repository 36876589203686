.aboutHome {
  height: auto;
  padding-top: 80px;
}
.aboutHome #heading {
  text-align: left;
  padding: 0;
}
.aboutHome .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutHome .right {
  padding: 80px 50px;
}
.aboutHome .items {
  margin-top: 50px;
}
.aboutHome .item {
  background-color: #fff;
  margin-top: 30px;
  padding: 20px;
  transition: 0.5s;
}
.aboutHome .img {
  width: 200px;
}

.top-image{
  display: none;
}

.aboutHome img {
  width: 70px;
  height: 70px;
}
.aboutHome .item h2 {
  font-size: 20px;
  margin-bottom: 15px;
}
.aboutHome .item p {
  color: #999999;
}
.aboutHome .item:hover {
  background-color: #fe1f59;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
}
.aboutHome .item:hover p {
  color: #fff;
}
/*----------awrapper------------*/
.awrapper {
  background-color: #1C2120;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 26vh;
  color: #F6B548;
}
.awrapper .box {
  padding: 70px 0;
  z-index: 10000 !important;

  
}
.awrapper img {
  margin-right: 30px;
}
.awrapper h1 {
  font-size: 40px;
}
.awrapper h3 {
  font-size: 15px;
  font-weight: 500;
  
}
/*----------awrapper------------*/
@media screen and (max-width: 768px) {
  .aboutHome .container {
    flex-direction: column-reverse;
  }
  .aboutHome .right {
    padding: 20px;
  }
  .aboutHome .items {
    margin-top: 0;
  }
  .aboutHome .row {
    width: 100%;
    /* margin-top: 20px; */
  }
  .aboutHome .row img{
    /* width: 100%; */
    margin-top: 30px;
    margin-bottom: 5px;
  }
  .awrapper .box{
    padding: 30px 0;
  }
  .awrapper {
padding-bottom: 300px !important;
    height: 90vh;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  .top-image{
    display: block;
  }
  .bottom-image{
    display: none;
  }

  .first-image-about{
    margin-top: 20px !important;
  }
  .bottom-text{
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1280px) {
  .aboutHome .right{
    padding: 10px 10px;
  }
}