.contacts iframe {
  width: 100%;
  height: 90vh;
  border: none;
}
.contacts .right {
  padding: 50px;
}
.contacts{
  padding: 0 0 90px;
  margin-top: 150px !important;
  margin: -110px !important;
}
.contacts h1 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 50px !important;
}
.contacts p {
  color: grey;
}
/* .contact-item{
  height: 150px !important;
} */
.contacts .items {
  margin-bottom: 10px;
}
.contacts .box p {
  font-size: 17px;
  margin-top: 10px;
}
.contacts .row {
  width: 100%;
}

.contact-item .form-title-priz{
  margin-bottom: 50px;
}


.contacts textarea,
.contacts input {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid grey;
}
form .flexSB input:nth-child(1) {
  margin-right: 10px;
}
.contacts h3 {
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

/* .contacts span {
  color: #fe1f59;
  font-weight: 600;
  font-size: 15px;
  word-spacing: 5px;
} */


@media screen and (max-width: 1000px) {
  .contacts .container {
    flex-direction: column;
  }
  .contacts iframe {
    height: 100vh;
  }
  .contacts .row {
    width: 100%;
  }
  .contacts2{
    margin-top: 170px;
  }

  .contacts .right{
    padding: 10px;
  }

  .map{
    height: 360px !important;
  }

  .contacts h1 {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 90px !important;
  }

}


@media screen and (max-width: 1624px) {
 
  .contacts iframe {
    height: 100vh;
  }
  .contacts .row {
    width: 80%;
  }
  .contacts2{
    margin-top: 170px;
  }

  .contacts .right{
    padding: 10px;
  }

  .map{
    height: 860px !important;
  }

  .contacts h1 {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 90px !important;
  }

  .contact-form-contact .amoforms_iframe{
    width: 500px !important;
  }

}
