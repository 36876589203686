.vidMom {
    margin-top: 75px;
    margin-left: 10%;
    width: 80%;
    border: "10px solid #f6b548";
    margin-bottom: 60px;
    
}

.ifarmeVideo{
    width: 100%;
    height: 848px;
}

@media screen and (max-width: 768px) {
    .ifarmeVideo{
        width: 100%;
        height: 250px;
    }
}