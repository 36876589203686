/*--------head------------*/
.head {
  padding: px 0;
  color: #fff;
}
.logo img {
  width: 120px;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 100;
}

.language-container {
  display: flex;
  align-items: center;
  background-color: #1c2120;
  color: #fff;
  border: 1px solid #f5b921;
  padding: 0px 12px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  appearance: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.language-icon {
  margin-right: 5px;
  color: #fff;
}

.language-select {
  background-color: #1c2120;
  color: #fff;
  padding: 0px 12px;
  border: 0px !important;
  cursor: pointer;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.language-select option {
  background-color: #1c2120;
  color: #fff;
}

.social {
  z-index: 100;
}
.head .icon {
  margin-left: 10px;
}
/*--------head------------*/
/*--------header------------*/
header {
  /* background-color: rgba(255, 255, 255, 0.2); */
  margin: 0 30px;
}
header .nav {
  display: flex !important;
}
header li {
  margin-right: 40px;
}
header ul {
  padding: 30px 20px;
}
header ul li a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
}
header ul li a:hover {
  color: #fe1f59;
}
.start {
  background-color: #fe1f59;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}
.toggle {
  display: none;
}

.container {
  justify-self: start;
  align-items: center;
  align-self: center;
}

/*--------header------------*/
@media screen and (max-width: 768px) {
  .start {
    clip-path: none;
  }
  .social {
    display: none;
  }
  header {
    margin: 0;
    background-color: #1c2120;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 10px;
  }
  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: -10px;
    top: -63px;
  }
  .mobile-nav {
    /* position: absolute; */
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #1c2120;
  }

  .head {
    background-color: rgba(255, 255, 255, 0.541);
  }
}

.navbar {
  background-color: #1c2120;
  background-size: cover;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
}

.nav-logo {
  color: #f5b921;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  margin-right: 2rem;
  width: 100% !important;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem !important;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #ffdd40;
}

.nav-item.active {
  color: #ffdd40;
  border: 1px solid #ffdd40;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 93px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    display: flex;
    left: -370px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #ffdd40;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 120%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #ffdd40;
  }

  .language-container {
    display: flex;
    align-items: center;
    background-color: #1c2120;
    color: #fff;
    border: 1px solid #f5b921;
    padding: 0px 12px;
    width: fit-content;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    appearance: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
}

.dropbtn {
  background-color: #ffdd40;
  color: white;
  padding: 7px 15px;
  margin-left: 15px;
  border-radius: 24px;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  transition: 1s;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  /* background-color: #f9f9f9; */
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 1;
  transition: 1s;
}

/* Links inside the dropdown */

/* Change color of dropdown links on hover */


/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  /* margin-top: 10px; */
  display: block;
  transition: 1s;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #fcd10f;
  transition: 0.5s;
}
