.exampleCarousel1{
  margin-left: 20px;
}

.slider-container2 {
  width: 97%;
  height: 300px; /* Установите желаемую высоту слайдера */
  /* margin-left: 5px; */
}

.slide-image {
  width: 70%;
  height: 100%;
  object-fit:contain;
}


@media screen and (max-width: 768px) {
  .exampleCarousel1 img{
    padding: 10px;
  }
  .slide-image {
    width: 80%;
    height: 100%;
    object-fit:contain;
  }
  .exampleCarousel1{
    margin-left: 0px;
  }
 
}