/* General Footer Styles */
footer {
  background-color: #1c2120;
  padding: 20px 0;
}

footer .container {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  grid-gap: 30px;
  align-items: start;
}

footer .box {
  color: white;
}

footer .box h1, footer .box h3 {
  margin-bottom: 20px;
  font-weight: 500;
  text-align: left;
}

footer .box ul {
  list-style: none;
  padding: 0;
}

footer .box ul li {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

footer .box ul li i {
  margin-right: 10px;
}

footer .box a {
  color: white;
  text-decoration: none;
}

footer .box a:hover {
  color: #F6B548;
}

footer .logo span {
  color: #F6B548;
  font-size: 14px;
}

footer .logo p {
  color: grey;
  margin: 15px 0;
}

footer .logo .icon {
  background-color: #F6B548;
  color: white;
  margin-right: 10px;
  /* padding: 10px; */
  border-radius: 50%;
}

.social-icons {
  display: flex;
  margin-top: 15px;
}

.legal {
  text-align: center;
  padding: 20px 0;
  color: grey;
  background-color: #1c2120;
}

.legal p {
  font-size: 15px;
}

.legal a {
  color: white;
  text-decoration: none;
}

.legal a:hover {
  color: #F6B548;
}

.address-text {
  width: 350px;
}


/* Responsive Styles */
@media screen and (max-width: 768px) {
  footer .container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .address-text{
    width: auto;
  }

  footer .box {
    margin-bottom: 20px;
  }

  footer .logo span,p{
    text-align: left !important;
  }

  .copyright{
    text-align: center !important;
  }
}

/* Newsletter Styles */
.newletter {
  background-color: #F6B548;
  padding: 50px 0;
  color: #fff;
}

.newletter .right,
.newletter .left {
  padding-right: 50px;
}

.hidden {
  display: none;
}

.footer-form {
  display: block;
}

@media screen and (max-width: 768px) {
  .footer-form {
    display: none;
  }
}

.newletter h1 {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}

.newletter .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

li {
  color: white !important;
}

.link,
.last {
  color: #fff !important;
}

.newletter input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  height: 30px;
}

.newletter i {
  padding: 12px;
  color: #F6B548;
  background-color: #fff;
}

.footer .link li::before {
  content: "⟶";
  color: #F6B548;
  margin-right: 5px;
}

.footer .items img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}

.footer .items span,
.footer .items i {
  font-size: 12px;
  color: #F6B548;
  margin-right: 5px;
  text-transform: capitalize;
}

footer h4 {
  font-weight: 400;
  margin-top: 5px;
}

